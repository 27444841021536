const contentUS = [
	{
	  "name": "IN-SCREEN",
	  "key": 0,
	  "units": [
		{
		  "label": "In-Screen",
		  "path": "/In-Screen",
		  "url": "https://embed.getguru.com/card/cMKaXRki/InScreen-Specs",
		  "type": "IN-SCREEN",
		  "key": 0,
		  "id": 0
		},
		{
		  "label": "In-Screen Frame",
		  "path": "/In-Screen-Frame",
		  "url": "https://embed.getguru.com/card/cBEqzLki/InScreen-Frame-Specs",
		  "type": "IN-SCREEN",
		  "key": 1,
		  "id": 1
		},
		{
		  "label": "In-Screen Expandable Corner",
		  "path": "/In-Screen-Expandable-Corner",
		  "url": "https://embed.getguru.com/card/cAKnr9Ei/InScreen-Expandable-Corner-Specs",
		  "type": "IN-SCREEN",
		  "key": 2,
		  "id": 2
		},
		{
		  "label": "In-Screen Expandable",
		  "path": "/In-Screen-Expandable",
		  "url": "https://embed.getguru.com/card/T96ybe8c/InScreen-Expandable-Specs",
		  "type": "IN-SCREEN",
		  "key": 3,
		  "id": 3
		},
		{
		  "label": "In-Screen Expandable Video",
		  "path": "/In-Screen-Expandable-Video",
		  "url": "https://embed.getguru.com/card/cnpxKRAi/InScreen-Expandable-Video-Specs",
		  "type": "IN-SCREEN",
		  "key": 4,
		  "id": 4
		}
	  ]
	},
	{
	  "name": "IN-IMAGE",
	  "key": 1,
	  "units": [
		{
		  "label": "In-Image",
		  "path": "/In-Image",
		  "url": "https://embed.getguru.com/card/TBEq9bAc/InImage-Specs",
		  "type": "IN-IMAGE",
		  "key": 0,
		  "id": 5
		},
		{
		  "label": "In-Image Expandable",
		  "path": "/In-Image-Expandable",
		  "url": "https://embed.getguru.com/card/iXpxqRrT/InImage-Expandable-Specs",
		  "type": "IN-IMAGE",
		  "key": 1,
		  "id": 6
		},
		{
		  "label": "In-Image Canvas",
		  "path": "/In-Image-Canvas",
		  "url": "https://embed.getguru.com/card/cy6oxGdi/Canvas-Specs",
		  "type": "IN-IMAGE",
		  "key": 2,
		  "id": 7
		},
		{
		  "label": "In-Image 6s Video Canvas",
		  "path": "/In-Image-6s-Video-Canvas",
		  "url": "https://embed.getguru.com/card/Tk6yqbMc/6Sec-Video-Canvas-Specs",
		  "type": "IN-IMAGE",
		  "key": 3,
		  "id": 8
		}
	  ]
	},
	{
	  "name": "IN-ARTICLE",
	  "key": 2,
	  "units": [
		{
		  "label": "Mobile Scroller",
		  "path": "/Mobile-Scroller",
		  "url": "https://embed.getguru.com/card/iz8RdjyT/Mobile-Scroller-Specs",
		  "type": "IN-ARTICLE",
		  "key": 0,
		  "id": 9
		},
		{
		  "label": "Hang Time Display",
		  "path": "/Hang-Time-Display",
		  "url": "https://embed.getguru.com/card/Td5rL9nc/Hang-Time-Display-Specs",
		  "type": "IN-ARTICLE",
		  "key": 1,
		  "id": 10
		},
		{
		  "label": "Hang Time Video",
		  "path": "/Hang-Time-Video",
		  "url": "https://embed.getguru.com/card/TBzRoppc/Hang-Time-Video-Specs",
		  "type": "IN-ARTICLE",
		  "key": 2,
		  "id": 11
		}
	  ]
	},
	{
	  "name": "SKINS",
	  "key": 3,
	  "units": [
		{
		  "label": "Mobile Skin",
		  "path": "/Mobile-Skin",
		  "url": "https://embed.getguru.com/card/T58R5bBc/Mobile-Skin-Specs",
		  "type": "SKINS",
		  "key": 0,
		  "id": 12
		},
		{
		  "label": "Desktop Skin",
		  "path": "/Desktop-Skin",
		  "url": "https://embed.getguru.com/card/T98RxMgc/Desktop-Skin-Specs",
		  "type": "SKINS",
		  "key": 1,
		  "id": 13
		}
	  ]
	},
	{
	  "name": "IN-VIDEO",
	  "key": 4,
	  "units": [
		{
		  "label": "In-Video Spark",
		  "path": "/In-Video-Spark",
		  "url": "https://embed.getguru.com/card/iXp99eyT/InVideo-Spark-Specs",
		  "type": "IN-VIDEO",
		  "key": 0,
		  "id": 14
		},
		{
		  "label": "In-Video Squeezeback",
		  "path": "/In-Video-Squeezeback",
		  "url": "https://embed.getguru.com/card/cMbeyByi/InVideo-Squeezeback-Specs",
		  "type": "IN-VIDEO",
		  "key": 1,
		  "id": 15
		},
		{
		  "label": "In-Video Spotlight",
		  "path": "/In-Video-Spotlight",
		  "url": "https://embed.getguru.com/card/iqxGabpT/InVideo-Spotlight-Specs",
		  "type": "IN-VIDEO",
		  "key": 2,
		  "id": 16
		}
	  ]
	},
	{
	  "name": "IN-SLOT",
	  "key": 5,
	  "units": [
		{
		  "label": "Contextual OLV",
		  "path": "/Contextual-OLV",
		  "url": "https://embed.getguru.com/card/cbdMazAi/Contextual-OLV-Specs",
		  "type": "IN-SLOT",
		  "key": 0,
		  "id": 17
		},
		{
		  "label": "Standard 320x50",
		  "path": "/Standard-320x50",
		  "url": "https://embed.getguru.com/card/cp699Eri/Standard-320x50-Specs",
		  "type": "IN-SLOT",
		  "key": 1,
		  "id": 18
		},
		{
		  "label": "Standard 728x90",
		  "path": "/Standard-728x90",
		  "url": "https://embed.getguru.com/card/co655E9i/Standard-728x90-Specs",
		  "type": "IN-SLOT",
		  "key": 2,
		  "id": 19
		},
		{
		  "label": "Display 320x50",
		  "path": "/Display-320x50",
		  "url": "https://embed.getguru.com/card/ig6qbrXT/InSlot-320x50-Specs",
		  "type": "IN-SLOT",
		  "key": 3,
		  "id": 20
		},
		{
		  "label": "Display 728x90",
		  "path": "/Display-728x90",
		  "url": "https://embed.getguru.com/card/Tx6EzK8c/InSlot-728x90-Specs",
		  "type": "IN-SLOT",
		  "key": 4,
		  "id": 21
		},
		{
		  "label": "Display 300x250",
		  "path": "/Display-300x250",
		  "url": "https://embed.getguru.com/card/c86EEeji/InSlot-300x250-Specs",
		  "type": "IN-SLOT",
		  "key": 5,
		  "id": 22
		},
		{
		  "label": "Display 160x600",
		  "path": "/Display-160x600",
		  "url": "https://embed.getguru.com/card/cLEoo6ki/InSlot-160x600-Specs",
		  "type": "IN-SLOT",
		  "key": 6,
		  "id": 23
		},
		{
		  "label": "Display 468x60",
		  "path": "/Display-468x60",
		  "url": "https://embed.getguru.com/card/cy6EEMRi/InSlot-468x60-Specs",
		  "type": "IN-SLOT",
		  "key": 7,
		  "id": 24
		},
		{
		  "label": "Display 640x480",
		  "path": "/Display-640x480",
		  "url": "https://embed.getguru.com/card/ik655zpT/InSlot-640x480-Specs",
		  "type": "IN-SLOT",
		  "key": 8,
		  "id": 25
		},
		{
		  "label": "Display 970x90",
		  "path": "/Display-970x90",
		  "url": "https://embed.getguru.com/card/cAKLL6Gi/InSlot-970x90-Specs",
		  "type": "IN-SLOT",
		  "key": 9,
		  "id": 26
		}
	  ]
	}
  ];  

const contentUK = [
	{
		name: 'Proprietary',
		key: 0,
		units: [
			{
				label:'In-Screen',
				path: '/In-Screen',
				url: 'https://embed.getguru.com/card/irA5M6oT/InScreen-UK-Specs',
				type: 'Proprietary',
				key: 0,
				id: 0
			},
			{
				label:'In-Screen Frame',
				path: '/In-Screen-Frame',
				url: 'https://embed.getguru.com/card/cMkX6K9i/InScreen-Frame-UK-Specs',
				type: 'Proprietary',
				key: 1,
				id: 1
			},
			{
				label:'In-Screen Expandable',
				path: '/In-Screen-Expandable',
				url: 'https://embed.getguru.com/card/cnAoA79i/InScreen-Expandable-UK-Specs',
				type: 'Proprietary',
				key: 2,
				id: 2
			},
			{
				label:'In-Screen Expandable Video',
				path: '/In-Screen-Expandable-Video',
				url: 'https://embed.getguru.com/card/cgA54e7i/InScreen-Expandable-Video-UK-Specs',
				type: 'Proprietary',
				key: 3,
				id: 3
			},
			{
				label:'In-Image',
				path: '/In-Image',
				url: 'https://embed.getguru.com/card/TKkr6pMc/InImage-UK-Specs',
				type: 'Proprietary',
				key: 4,
				id: 4
			},
			{
				label:'In-Image Expandable',
				path: '/In-Image-Expandable',
				url: 'https://embed.getguru.com/card/T4A5Mo5c/InImage-Expandable-UK-Specs',
				key: 5,
				id: 5
			},
			{
				label:'Canvas',
				path: '/Canvas',
				url: 'https://embed.getguru.com/card/TEg86pqc/Canvas-UK-Specs',
				type: 'Proprietary',
				key: 6,
				id: 6
			},
			{
				label:'6-Sec Video Canvas',
				path: '/6-Sec-Video-Canvas',
				url: 'https://embed.getguru.com/card/cRAajR7i/6Sec-Video-Canvas-UK-Specs',
				type: 'Proprietary',
				key: 7,
				id: 7
			},
			{
				label:'In-Screen Expandable Corner',
				path: '/In-Screen-Expandable-Corner',
				url: 'https://embed.getguru.com/card/iLg96E4T/InScreen-Expandable-Corner-UK-Specs',
				type: 'Proprietary',
				key: 8,
				id: 8
			},
			{
				label:'In-Article Shoppable',
				path: '/In-Article-Shoppable',
				url: 'https://embed.getguru.com/card/c5AqnaGi/InArticle-Shoppable-UK-Specs',
				type: 'Proprietary',
				key: 9,
				id: 9
			},
			{
				label:'In-Article Video',
				path: '/In-Article-Video',
				url: 'https://embed.getguru.com/card/cRAajKbi/InArticle-Video-UK-Specs',
				type: 'Proprietary',
				key: 10,
				id: 25
			},
			{
				label:'Desktop Skin',
				path: '/Desktop-Skin',
				url: 'https://embed.getguru.com/card/ieAnz4dT/Desktop-Skins-UK-Specs',
				type: 'Proprietary',
				key: 11,
				id: 26
			},
			{
				label:'Mobile Skin',
				path: '/Mobile-Skin',
				url: 'https://embed.getguru.com/card/ceAnz4Mi/Mobile-Skin-UK-Specs',
				type: 'Proprietary',
				key: 12,
				id: 27
			},
			{
				label:'Mobile Scroller',
				path: '/Mobile-Scroller',
				url: 'https://embed.getguru.com/card/iBgApLrT/Mobile-Scroller-UK-Specs',
				type: 'Proprietary',
				key: 13,
				id: 28
			}
		]
	},
	{
		name: 'CTV/OTT',
		key: 1,
		units: [
			{
				label:'In-Video Snipe',
				path: '/In-Video-Snipe',
				url: 'https://embed.getguru.com/card/TrA5M6ac/InVideo-Snipe-UK-Specs',
				type: 'Proprietary',
				key: 0,
				id: 23
			},
			{
				label:'In-Video Corner',
				path: '/In-Video-Corner',
				url: 'https://embed.getguru.com/card/cnAaR94i/InVideo-Corner-UK-Specs',
				type: 'Proprietary',
				key: 1,
				id: 24
			}
		]
	},
	{
		name: 'Social',
		key: 2,
		units: [
			{
				label:'Native',
				path: '/Native',
				url: 'https://embed.getguru.com/card/T6A5MzKc/Native-UK-Specs',
				type: 'Social',
				key: 0,
				id: 10
			},
			{
				label:'Story',
				path: '/Story',
				url: 'https://embed.getguru.com/card/TeAnzp6c/Story-UK-Specs',
				type: 'Social',
				key: 1,
				id: 11
			}
		]
	},
	{
		name: 'Interactive',
		key: 3,
		units: [
			{
				label:'Carousel',
				path: '/Carousel',
				url: 'https://embed.getguru.com/card/crA5MR7i/Interactive-Carousel-UK-Specs',
				type: 'Interactive',
				key: 0,
				id: 12
			},
			{
				label:'Scroll',
				path: '/Scroll',
				url: 'https://embed.getguru.com/card/cEg86EXi/Interactive-Scroll-UK-Specs',
				type: 'Interactive',
				key: 1,
				id: 13
			}
		]
	},
	{
		name: 'Standard',
		key: 4,
		units: [
			{
				label:'728x90',
				path: '/Standard-728x90',
				url: 'https://embed.getguru.com/card/TEg8kR8c/Standard-728x90-UK-Specs',
				type: 'Standard',
				key: 0,
				id: 14
			},
			{
				label:'320x50',
				path: '/Standard-320x50',
				url: 'https://embed.getguru.com/card/i5AqGzET/Standard-320x50-UK-Specs',
				type: 'Standard',
				key: 1,
				id: 15
			}
		]
	},
	{
		name: 'Display',
		key: 5,
		units: [
			{
				label:'320x50',
				path: '/Display-320x50',
				url: 'https://embed.getguru.com/card/T5AqGz8c/InSlot-320x50-UK-Specs',
				type: 'Display',
				key: 0,
				id: 16
			},
			{
				label:'728x90',
				path: '/Display-728x90',
				url: 'https://embed.getguru.com/card/iqA5GkjT/InSlot-728x90-UK-Specs',
				type: 'Display',
				key: 1,
				id: 17
			},
			{
				label:'300x250',
				path: '/Display-300x250',
				url: 'https://embed.getguru.com/card/iEg86oRT/InSlot-300x250-UK-Specs',
				type: 'Display',
				key: 2,
				id: 18
			},
			{
				label:'160x600',
				path: '/Display-160x600',
				url: 'https://embed.getguru.com/card/iMkX65zT/InSlot-160x600-UK-Specs',
				type: 'Display',
				key: 3,
				id: 19
			},
			{
				label:'468x60',
				path: '/Display-468x60',
				url: 'https://embed.getguru.com/card/ibAazjgT/InSlot-468x60-UK-Specs',
				type: 'Display',
				key: 4,
				id: 20
			},
			{
				label:'640x480',
				path: '/Display-640x480',
				url: 'https://embed.getguru.com/card/czA5Mpdi/InSlot-640x480-UK-Specs',
				type: 'Display',
				key: 5,
				id: 21
			},
			{
				label:'970x90',
				path: '/Display-970x90',
				url: 'https://embed.getguru.com/card/cBgApGgi/InSlot-970x90-UK-Specs',
				type: 'Display',
				key: 6,
				id: 22
			}
		]
	}
]

const navContent = process.env.REACT_APP_GEO === 'UK' ? contentUK : contentUS;

export {navContent};